import { ActionNames } from '../api/Settings'

const initialState = {
   email: '',
   error: { isError: false, err: '' },
   successful: null,
   isSending: false,
   redirectUrl: null,
}

export interface ForgotState {
   email: string,
   error: { isError: boolean, err: string },
   successful: boolean,
   isSending: boolean,
   redirectUrl: (string | Location) & Location,
}

export const forgot = (state = initialState, action) => {
   switch (action.type) {
      case ActionNames.ERROR:
         return Object.assign({}, state, { error: { isError: true, err: action.err } })

      case ActionNames.CHANGE_EMAIL:
         return Object.assign({}, state, { email: action.email, successful: null })

      case ActionNames.EMAIL_FOUND:
         return Object.assign({}, state, {
            successful: action.result.successful,
            redirectUrl: action.result.redirectUrl
         })

      case ActionNames.IS_SENDING:
         return Object.assign({}, state, { isSending: action.payload.isSending })

      default:
         return state
   }
}