if (typeof jQuery === 'undefined') {
   throw new Error('AjaxWrapper requires jQuery')
}

const AjaxWrapper = {};

(function (wrapper) {

   /**
    * Default start shows the progress bar
    */
   const defaultStart = function () {

   }

   /**
    * Default stop hides the progress bar
    */
   const defaultStop = function () {

   }

   /**
    * Failing method
    * @param jqXHR a string describing the type of error that occurred and an optional exception object
    * @param textStatus "timeout", "error", "abort", or "parsererror"
    * @param errorThrown  the textual portion of the HTTP status, such as "Not Found" or "Internal Server Error
    */
   const defaultFail = function () {
      // if (window.console) {
      //     window.console.log(jqXHR);
      // }
      // if (Toaster) {
      //     Toaster.showError(
      //         {
      //             content: "Det har vært en feil"
      //         });
      // }
   }

   /**
    * Calling the server looks a little like calling the server in GWT
    * @param url the destination of the call
    * @param callback for handling successfully returned data
    * @param options optional jquery options
    */
   // @ts-ignore
   wrapper.ajax = function (url, callback, options) {
      let settings = {
         method: 'post',
         beforeSend: defaultStart,
         complete: defaultStop,
         error: defaultFail
      }

      settings = $.extend(settings, options)

      $.ajax(url, settings).done(function (data) {
         if (callback != null) {
            callback(data)
         }
      })
   }

   /**
    * Calling the server looks a little like calling the server in GWT
    * @param url the destination of the call
    * @param callback for handling successfully returned data
    * @param options optional jquery options
    */
   // @ts-ignore
   wrapper.poll = function (url, callback, options) {
      let settings = {
         method: 'post',
         // @ts-ignore
         error: function () {
            defaultFail();
            wrapper.pollStop()
         }
      }

      settings = $.extend(settings, options)

      $.ajax(url, settings).done(function (data) {
         if (callback != null) {
            callback(data)
         }
      })
   }

   // @ts-ignore
   wrapper.isPolling = false
   /**
    * Hides the progress bar
    */
   // @ts-ignore
   wrapper.pollStop = function () {
      // @ts-ignore
      if (wrapper.isPolling) {
         // @ts-ignore
         wrapper.isPolling = false
         defaultStop()
      }
   }

   /**
    * Shows the progress bar
    */
   // @ts-ignore
   wrapper.pollStart = function () {
      // @ts-ignore
      if (!wrapper.isPolling) {
         // @ts-ignore
         wrapper.isPolling = true
         defaultStart()
      }
   }

}(AjaxWrapper))

export default AjaxWrapper