import { Paths } from './Settings'
import AjaxWrapper from '../utilities/AjaxWrapper'
import { Requireable, string } from 'prop-types'

const localizedStrings: { [key: string]: string } = {}

export const localize = {
   locale: string,
   translate: (key: string) => localizedStrings[key],
   format: function (key: string, ...replacements: string[]) {
      let result = localizedStrings[key]
      let i = 0
      for (const replacement of replacements) {
         result = result.replace('{' + i + '}', replacement)
         i++
      }
      return result
   }
}


export function loadAll() {
   return load('')
}

export function load(keys: string) {
   return new Promise<void>(
      function (resolve, reject) {
         // @ts-ignore
         AjaxWrapper.ajax(
            Paths.restish + 'com.pls.login.server.localizations.Localize',
            function (result: { localizations: string; locale: { key: Requireable<string>; }; }) {
               Object.assign(localizedStrings, result.localizations)
               localize.locale = result.locale.key
               resolve()
            },
            {
               data: {
                  json: JSON.stringify(
                     {
                        keys: keys
                     }
                  )
               },
               error: (jqXHR: string, textStatus: string, errorThrown: boolean) => reject(errorThrown)
            })
      })
}