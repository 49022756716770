import { ActionNames } from '../api/Settings'
import { localize } from '../api/Localize'

const initialState = {
   error: { isError: false, err: '' },
   password: null,
   repeatPassword: null,
   isSending: false,
   redirectUrl: null,
   username: null,
   rulesDialogOpen: false,
}

export interface NewPasswordState {
   error: { isError: boolean, err: string },
   password: string,
   repeatPassword: string,
   isSending: boolean,
   redirectUrl: (string | Location) & Location,
   username: string,
   rulesDialogOpen: boolean,
}

export const newPassword = (state = initialState, action) => {
   switch (action.type) {
      case ActionNames.ERROR:
         return Object.assign({}, state, { error: { isError: true, err: action.err } })

      case ActionNames.CHANGE_PASSWORD:
         return Object.assign({}, state, { password: action.password, error: { isError: false, err: '' } })

      case ActionNames.CHANGE_REPEAT_PASSWORD:
         return Object.assign({}, state, { repeatPassword: action.repeatPassword, error: { isError: false, err: '' } })

      case ActionNames.PASSWORD_CHANGE_RESULT:
         let result = Object.assign({}, state, { redirectUrl: action.result.redirectUrl })
         if (!action.result.success) {
            // get the error, so we can show it and take appropriate action
            if (action.result.loginStatus === 'PASSWORD_CHANGE_NOT_ALLOWED') {
               result = Object.assign({}, result, {
                  error: {
                     isError: true,
                     err: localize.translate('singleSignonError')
                  }
               })
            }
            if (action.result.loginStatus === 'ILLEGAL_PASSWORD') {
               result = Object.assign({}, result, {
                  error: {
                     isError: true,
                     err: localize.translate('illegalPasswordError')
                  }
               })
            }
         }
         return result

      case ActionNames.GOT_USER_FROM_CONTEXT:
         return Object.assign({}, state, { username: action.user.name })

      case ActionNames.IS_SENDING:
         return Object.assign({}, state, { isSending: action.payload.isSending })

      case ActionNames.TOGGLE_RULES_DIALOG:
         const isOpen = state.rulesDialogOpen
         return Object.assign({}, state, { rulesDialogOpen: !isOpen })

      default:
         return state
   }
}