import React, { useEffect, useState } from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'


/**
 * Google Chrome does not trigger a change event when input fields are autofilled.
 * Neither does it actually insert the filled value into the input, meaning input.value === "";
 * It does however style the input with a pseudo element '-webkit-autofill'
 * Upon detecting this pseudo styling on the input element we are able to change the input styling as desired
 */

type AutofillCorrectingTextFieldProps = {
   onChange?: (event) => void
   id: string
}

export const AutofillCorrectingTextField: React.FC<(AutofillCorrectingTextFieldProps & TextFieldProps)> = (props) => {
   const [forceShrinkLabel, setForceShrinkLabel] = useState(false)

   const checkAutofill = (input) => {
      if (!input.val()) {
         try {
            const autofilledElements = $('input:-webkit-autofill')
            const isAutofilled = autofilledElements.filter(input)
            if (isAutofilled.length === 0) {
               return false // No autofill, try again
            }
         } catch (err) {
            // This error is most likely due to FF and IE not knowing about '-webkit-autofill'.
            // Therefore there is no need to check for autofill again.
            return true
         }
      }
      // Autofill was detected, or input already has value. Fixing input styling and ending recurring checks
      setForceShrinkLabel(true)
      return true
   }

   /**
    * When component has mounted check the input for autofilled content.
    * There is no guarantee for when Chrome autofills input fields,
    * so this check is performed in 100ms intervals at most 20 times.
    */
   useEffect(() => {
      if (props.id) {
         const input = $('#' + props.id)

         if (!checkAutofill(input)) {
            let interval = 0
            const intervalId = setInterval(() => {
               if (checkAutofill(input) || interval++ >= 20) {
                  clearInterval(intervalId)
               }
            }, 100)
         }
      }
   }, [])

   const onChange = (event) => {
      setForceShrinkLabel(false)
      if (props.onChange) {
         props.onChange(event)
      }
   }


   const labelProps = forceShrinkLabel ? { shrink: true } : {}
   return (
      <TextField
         {...props}
         onChange={onChange}
         InputLabelProps={labelProps}
      />
   )
}