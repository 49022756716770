import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ContentBox } from '../../views/ContentBox'
import { SupportBox } from '../../views/SupportBox'
import { localize } from '../../api/Localize'
import { emailChange, sendNewPasswordMail } from '../../actions/Actions'
import Button from '@material-ui/core/Button'
import { AutofillCorrectingTextField } from '../../views/AutofillCorrectingTextField'
import { Link } from 'react-router'
import { ForgotState } from '../../reducers/ForgotReducer'


class ForgotRoute extends Component<({ forgot: ForgotState } & DispatchMethods)> {
   constructor(props) {
      super(props)
   }

   render() {
      const error = this.props.forgot.error
      if (error.isError) {
         throw Error(error.err)
      }

      const email = this.props.forgot.email
      const successful = this.props.forgot.successful
      const isSending = this.props.forgot.isSending
      const redirectUrl = this.props.forgot.redirectUrl
      const textFieldWarning = successful != null && !successful ? localize.translate('emailInvalid') : null
      const onKeyPress = (event) => {
         if (event.charCode == 13) {
            this.props.onSend(email)
         }
      }


      if (successful && redirectUrl != null) {
         window.location = redirectUrl
      }

      return (
         <div>
            <SupportBox/>

            <div className="content-div">

               <ContentBox title={localize.translate('ForgotPasswordFormHeader')}>

                  <div className="section">
                     <p>{localize.translate('ForgotPasswordInfoText')}</p>
                  </div>

                  {/*Email*/}
                  <div className="section">
                     <AutofillCorrectingTextField
                        id="email"
                        variant="filled"
                        className="text-field"
                        error={textFieldWarning != null}
                        helperText={textFieldWarning != null ? textFieldWarning : ''}
                        disabled={isSending}
                        onKeyPress={onKeyPress}
                        label={localize.translate('email')}
                        onChange={this.props.onEmailChange}
                        margin="none"
                        fullWidth
                     />
                  </div>

                  {/*Send*/}
                  <div className="section">
                     <Button
                        variant="contained"
                        className="login-button"
                        // labelposition={'before'}
                        fullWidth
                        disabled={isSending}
                        onClick={() => this.props.onSend(email)}
                     >
                        {localize.translate('send')}
                     </Button>
                  </div>

                  <div className="footer">
                     {/*forgot password*/}
                     <div className="footer-link">
                        <Link className="link"
                              to={window.baseUrl}
                        >
                           {localize.translate('goToLogin')}
                        </Link>
                     </div>
                  </div>

               </ContentBox>
            </div>
         </div>
      )
   }
}

export const View = ForgotRoute

const mapStateToProps = (state, ownProps) => {
   return {
      forgot: state.forgot,
   }
}

interface DispatchMethods {
   onEmailChange: (event) => void
   onSend: (email) => void
}

const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      onEmailChange: (event) => {
         const email = event.target.value
         return dispatch(emailChange(email))
      },
      onSend: (email) => {
         return dispatch(sendNewPasswordMail(email))
      }
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(View)