import { Paths } from './Settings'
import AjaxWrapper from '../utilities/AjaxWrapper'

export function login(username: string, password: string | null, rememberMe: string | null, redirectUrl: string | null) {
   return new Promise(
      function (resolve, reject) {
         /**
          * Tries to log in a user based on username and password as typed in the textFields
          */
         //@ts-ignore
         AjaxWrapper.ajax(
            Paths.restish + 'com.pls.login.server.commands.Login',
            function (result: unknown) {
               resolve(result)
            },
            {
               data: {
                  json: JSON.stringify(
                     {
                        username: username,
                        password: password,
                        rememberMe: rememberMe,
                        redirectUrl: redirectUrl,
                     }
                  )
               },
               //@ts-ignore
               error: (jqXHR, textStatus, errorThrown) => reject(errorThrown, jqXHR.status)
            })
      })
}

export function verifySSO(username: string, password: string, redirectUrl: string | null) {
   return new Promise(
      function (resolve, reject) {
         /**
          * Sends login information for the purpose of connecting an SSO user to a xact user
          */
         //@ts-ignore
         AjaxWrapper.ajax(
            Paths.restish + 'com.pls.login.server.commands.SSOVerification',
            function (result) {
               resolve(result)
            },
            {
               data: {
                  json: JSON.stringify(
                     {
                        username: username,
                        password: password,
                        redirectUrl: redirectUrl,
                     }
                  )
               },
               //@ts-ignore
               error: (jqXHR, textStatus, errorThrown) => reject(errorThrown, jqXHR.status)
            })
      })
}

export function ForgotPassword(email, ignoreMandatorySSO) {
   return new Promise(
      function (resolve, reject) {
         //@ts-ignore
         AjaxWrapper.ajax(
            Paths.restish + 'com.pls.login.server.commands.ForgotPassword',
            function (result) {
               resolve(result)
            },
            {
               data: {
                  json: JSON.stringify(
                     {
                        email: email,
                        ignoreMandatorySSO: ignoreMandatorySSO
                     }
                  )
               },
               //@ts-ignore
               error: (jqXHR, textStatus, errorThrown) => reject(errorThrown, jqXHR.status)
            })
      })
}

export function changePassword(password) {
   return new Promise(
      function (resolve, reject) {
         //@ts-ignore
         AjaxWrapper.ajax(
            Paths.restish + 'com.pls.login.server.commands.ChangePassword',
            function (result) {
               resolve(result)
            },
            {
               data: {
                  json: JSON.stringify(
                     {
                        password: password,
                     }
                  )
               },
               //@ts-ignore
               error: (jqXHR, textStatus, errorThrown) => reject(errorThrown, jqXHR.status)
            })
      })
}

export function sendVerificationKey(verificationKey) {
   return new Promise(
      function (resolve, reject) {
         //@ts-ignore
         AjaxWrapper.ajax(
            Paths.restish + 'com.pls.login.server.commands.VerifyKey',
            function (result) {
               resolve(result)
            },
            {
               data: {
                  json: JSON.stringify(
                     {
                        verificationKey: verificationKey,
                     }
                  )
               },
               //@ts-ignore
               error: (jqXHR, textStatus, errorThrown) => reject(errorThrown, jqXHR.status)
            })
      })
}

export function getUserFromContext() {
   return new Promise(
      function (resolve, reject) {
         //@ts-ignore
         AjaxWrapper.ajax(
            Paths.restish + 'com.pls.login.server.commands.GetUserFromContext',
            function (result) {
               resolve(result)
            },
            {
               data: {
                  json: JSON.stringify(
                     {}
                  )
               },
               //@ts-ignore
               error: (jqXHR, textStatus, errorThrown) => reject(errorThrown, jqXHR.status)
            })
      })
}

export function getUserData(username) {
   return new Promise(
      function (resolve, reject) {
         //@ts-ignore
         AjaxWrapper.ajax(
            Paths.restish + 'com.pls.login.server.commands.GetUser',
            function (result) {
               resolve(result)
            },
            {
               data: {
                  json: JSON.stringify(
                     {
                        username: username
                     }
                  )
               },
               //@ts-ignore
               error: (jqXHR, textStatus, errorThrown) => reject(errorThrown, jqXHR.status)
            })
      })
}