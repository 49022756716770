import React, { Component } from 'react'
import { SupportBox } from '../../views/SupportBox'
import { ContentBox } from '../../views/ContentBox'
import { localize } from '../../api/Localize'
import { Link } from 'react-router'

export default class BackoffRoute extends Component {

   render() {
      return (
         <div>

            <SupportBox/>

            <div className="content-div">
               <ContentBox title={localize.translate('backoffHeader')}>

                  <div className="section">
                     <p>{localize.translate('backoffInfoText')}</p>
                  </div>

                  <div className="footer">
                     <div className="footer-link">
                        <Link className="link"
                              to={window.baseUrl}
                        >
                           {localize.translate('goToLogin')}
                        </Link>
                     </div>
                  </div>
               </ContentBox>

            </div>
         </div>
      )
   }
}

