export const ActionNames = {
   ROUTE_CHANGE: 'RouteChange'
}

export function onRouteChange(route) {
   return (dispatch) => {
      dispatch({
         type: ActionNames.ROUTE_CHANGE,
         payload: route
      })
   }
}
