import React from 'react'
import { Snackbar, SnackbarContent } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { connect } from 'react-redux'
import { removeAlert } from '../actions/Actions'

type AlertSnackbarProps = {
   handleClose: () => void
   alert: any
}

const AlertSnackbar: React.FC<AlertSnackbarProps> = (props) => {
   const handleClose = () => {
      props.handleClose()
   }

   const show = props.alert.show
   const message = props.alert.message
   const isSuccess = props.alert.isSuccess

   return (
      <Snackbar
         open={show}
         autoHideDuration={3000}
         onClose={handleClose}
      >
         <SnackbarContent
            message={(
               <span style={{ display: 'flex', alignItems: 'center', }}>
                    <Icon style={{ fontSize: 20, opacity: 0.9, marginRight: 20 }}>
                        {isSuccess ? 'check' : 'error'}
                    </Icon>
                  {message}
                </span>
            )}
            style={isSuccess ? { backgroundColor: '#4caf50' } : { backgroundColor: '#f44336' }}
         />
      </Snackbar>
   )
}

const mapStateToProps = (state, ownProps) => {
   return {
      alert: state.alert,
   }

}

const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      handleClose: () => {
         return dispatch(removeAlert())
      }
   }

}

export default connect(mapStateToProps, mapDispatchToProps)(AlertSnackbar)