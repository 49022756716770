import * as Api from '../api/Api'
import { ActionNames } from '../api/Settings'
import { localize } from '../api/Localize'

export function login(username: string, password: string | null, rememberMe: string | null, redirectUrl: string | null) {
   return function (dispatch: (arg0: {
      type: string;
      payload?: { isLoggingIn: boolean; };
      result?: any;
      err?: string;
   }) => void) {
      dispatch(isLoggingIn(true))
      return Api.login(username, password, rememberMe, redirectUrl)
         .then(
            function (result: any) {
               dispatch(loggedIn(result))
               if (!result.successful) {
                  dispatch(isLoggingIn(false))
               }
            }
         )
         .catch(
            function (err) {
               dispatch(reportError(err))
               dispatch(isLoggingIn(false))
            }
         )
   }
}

export function verifySSO(username: string, password: string, redirectUrl: string | null) {
   return function (dispatch: (arg0: {
      type: string;
      payload?: { isLoggingIn: boolean; };
      result?: any;
      err?: string;
   }) => void) {
      dispatch(isLoggingIn(true))
      return Api.verifySSO(username, password, redirectUrl)
         .then(
            function (result) {
               dispatch(loggedIn(result))
               // @ts-ignore
               if (!result.successful) {
                  dispatch(isLoggingIn(false))
               }
            }
         ).catch(
            function (err) {
               dispatch(reportError(err))
               dispatch(isLoggingIn(false))
            }
         )
   }
}

export function sendNewPasswordMail(email: string) {
   return function (dispatch: (arg0: {
      type: string;
      payload?: { isSending: boolean; };
      result?: any;
      err?: string;
   }) => void) {
      dispatch(isSending(true))
      return Api.ForgotPassword(email, false)
         .then(
            function (result) {
               dispatch(emailFound(result))
               dispatch(isSending(false))
            }
         )
         .catch(
            function (err) {
               dispatch(isSending(false))
               dispatch(reportError(err))
            }
         )
   }
}

export function sendNewPasswordMailSSO(email: string) {
   return function (dispatch: (arg0: {
      (dispatch: (arg0: { type: string; alert: { message: string; isSuccess: boolean; }; }) => void): void;
      (dispatch: (arg0: { type: string; alert: { message: string; isSuccess: boolean; }; }) => void): void;
   }) => void) {
      return Api.ForgotPassword(email, false)
         .then(
            function () {
               dispatch(setAlert(localize.translate('emailSentSuccessfully'), true))
            }
         )
         .catch(
            function () {
               dispatch(setAlert(localize.translate('emailSentUnsuccessfully'), false))
            }
         )
   }
}

export function changePassword(password: string, repeatPassword: string) {
   return function (dispatch: (arg0: {
      type: string;
      payload?: { isSending: boolean; };
      result?: any;
      err?: string;
   }) => void) {
      if (password == null) {
         return
      }
      if (password !== repeatPassword) {
         return
      }
      //add swirl here if needed
      dispatch(isSending(true))
      return Api.changePassword(password)
         .then(
            function (result) {
               dispatch(passwordChanged(result))
               // @ts-ignore
               if (!result.successful) {
                  dispatch(isSending(false))
               }
            }
         )
         .catch(
            function (err) {
               dispatch(isSending(false))
               dispatch(reportError(err))
            }
         )
   }
}

export function sendVerificationKey(verificationKey: string) {
   return function (dispatch: (arg0: {
      type: string;
      payload?: { isSending: boolean; };
      result?: any;
      err?: string;
   }) => void) {
      //add swirl here if needed
      dispatch(isSending(true))
      return Api.sendVerificationKey(verificationKey)
         .then(
            function (result) {
               dispatch(gotVerificationKey(result))
               // @ts-ignore
               if (!result.successful) {
                  dispatch(isSending(false))
               }
            }
         )
         .catch(
            function (err) {
               dispatch(isSending(false))
               dispatch(reportError(err))
            }
         )
   }
}

export function getUserFromContext() {
   return function (dispatch: (arg0: {
      type: string;
      payload?: { isSending: boolean; };
      user?: any;
      err?: string;
   }) => void) {
      //add swirl here if needed
      dispatch(isSending(true))
      return Api.getUserFromContext()
         .then(
            function (result) {
               dispatch(gotUserFromContext(result))
               // @ts-ignore
               if (!result.successful) {
                  dispatch(isSending(false))
               }
            }
         )
         .catch(
            function (err) {
               dispatch(isSending(false))
               dispatch(reportError(err))
            }
         )
   }
}

export function getUserData(username: string) {
   return function (dispatch: (arg0: {
      type: string;
      payload?: { isSending: boolean; };
      user?: any;
      err?: string;
   }) => void) {
      dispatch(isSending(true))
      return Api.getUserData(username)
         .then(
            function (result) {
               dispatch(gotUser(result))
               // @ts-ignore
               if (!result.successful) {
                  dispatch(isSending(false))
               }
            }
         )
         .catch(
            function (err) {
               dispatch(isSending(false))
               dispatch(reportError(err))
            }
         )
   }
}

const loggedIn = function (result: unknown) {
   return {
      type: ActionNames.LOGGED_IN,
      result: result
   }
}

const isLoggingIn = function (isLoggingIn: boolean) {
   return {
      type: ActionNames.IS_LOGGING_IN,
      payload: { isLoggingIn }
   }
}


const reportError = function (err: string) {
   return {
      type: ActionNames.ERROR,
      err: err
   }
}

export function setAlert(message: string, isSuccess: boolean) {
   return function (dispatch: (arg0: { type: string; alert: { message: string; isSuccess: boolean; }; }) => void) {
      dispatch(
         {
            type: ActionNames.SET_ALERT,
            alert: {
               message: message,
               isSuccess: isSuccess
            }
         })
   }
}

export function removeAlert() {
   return function (dispatch: (arg0: { type: string; }) => void) {
      dispatch(
         {
            type: ActionNames.REMOVE_ALERT
         })
   }
}


export function passwordChange(password: string) {
   return function (dispatch: (arg0: { type: string; password: string; }) => void) {
      dispatch(
         {
            type: ActionNames.CHANGE_PASSWORD,
            password: password
         })
   }
}

export function usernameChange(username: string) {
   return function (dispatch: (arg0: { type: string; username: string; }) => void) {
      dispatch(
         {
            type: ActionNames.CHANGE_USERNAME,
            username: username
         })
   }
}

export function rememberMeChange(rememberMe: string) {
   return function (dispatch: (arg0: { type: string; rememberMe: string; }) => void) {
      dispatch(
         {
            type: ActionNames.CHANGE_REMEMBER_ME,
            rememberMe: rememberMe
         })
   }
}

export function toggleRulesDialog() {
   return function (dispatch: (arg0: { type: string; }) => void) {
      dispatch(
         {
            type: ActionNames.TOGGLE_RULES_DIALOG
         })
   }
}

function validateEmail(email: string) {
   const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
   return re.test(email)
}

export function emailChange(email: string) {
   return function (dispatch: (arg0: { type: string; email: string; }) => void) {
      dispatch(
         {
            type: ActionNames.CHANGE_EMAIL,
            email: email
         })
   }
}

const emailFound = function (result: unknown) {
   return {
      type: ActionNames.EMAIL_FOUND,
      result: result
   }
}

const isSending = function (isSending: boolean) {
   return {
      type: ActionNames.IS_SENDING,
      payload: { isSending }
   }
}


export function repeatPasswordChange(repeatPassword: string) {
   return function (dispatch: (arg0: { type: string; repeatPassword: string; }) => void) {
      dispatch(
         {
            type: ActionNames.CHANGE_REPEAT_PASSWORD,
            repeatPassword: repeatPassword
         })
   }
}

const passwordChanged = function (result: unknown) {
   return {
      type: ActionNames.PASSWORD_CHANGE_RESULT,
      result: result
   }
}


export function verificationKeyChange(verificationKey: string) {
   return function (dispatch: (arg0: { type: string; verificationKey: string; }) => void) {
      dispatch(
         {
            type: ActionNames.CHANGE_VERIFICATION_KEY,
            verificationKey: verificationKey
         })
   }
}

const gotVerificationKey = function (result: unknown) {
   return {
      type: ActionNames.GOT_VERIFICATION_KEY,
      result: result
   }
}

const gotUserFromContext = function (user: unknown) {
   return {
      type: ActionNames.GOT_USER_FROM_CONTEXT,
      user: user
   }
}

const gotUser = function (user: unknown) {
   return {
      type: ActionNames.GOT_USER,
      user: user
   }
}