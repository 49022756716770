import React from 'react'
import { SupportBox } from './SupportBox'
import { ContentBox } from './ContentBox'
import { localize } from '../api/Localize'


export const ErrorPage: React.FC = () => {
   return (
      <div>
         <SupportBox/>
         <div className="content-div">
            <ContentBox title={localize.translate('errorTitle')}>
               <div className="section">
                  <p>{localize.translate('errorMsg')}</p>
               </div>
               <div className="footer">
                  <div className="footer-link">
                     <a className="link"
                        href="javascript:window.location.reload(true)">{localize.translate('goBack')}</a>
                  </div>
               </div>
            </ContentBox>
         </div>
      </div>
   )
}
