const ActionNames = {
   ERROR: 'Error',
   LOGGED_IN: 'LOGGED_IN',

   IS_LOGGING_IN: 'IS_LOGGING_IN',
   CHANGE_PASSWORD: 'CHANGE_PASSWORD',
   CHANGE_USERNAME: 'CHANGE_USERNAME',
   CHANGE_REMEMBER_ME: 'CHANGE_REMEMBER_ME',
   REDIRECT_URL: 'REDIRECT_URL',

   CHANGE_EMAIL: 'CHANGE_EMAIL',
   EMAIL_FOUND: 'EMAIL_FOUND',
   IS_SENDING: 'IS_SENDING',

   GOT_USER_FROM_CONTEXT: 'GOT_USER_FROM_CONTEXT',
   CHANGE_REPEAT_PASSWORD: 'CHANGE_REPEAT_PASSWORD',
   PASSWORD_CHANGE_RESULT: 'PASSWORD_CHANGE_RESULT',
   CHANGE_VERIFICATION_KEY: 'CHANGE_VERIFICATION_KEY',
   GOT_VERIFICATION_KEY: 'GOT_VERIFICATION_KEY',
   TOGGLE_RULES_DIALOG: 'TOGGLE_RULES_DIALOG',

   GOT_USER: 'GOT_USER',

   SET_ALERT: 'SET_ALERT_STATE',
   REMOVE_ALERT: 'RESET_ALERT_STATE'
}

export default ActionNames