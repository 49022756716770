import React, { Component } from 'react'
import LoginRoute from './routes/LoginRoute'
import SSOVerificationRoute from './routes/SSOVerificationRoute'
import ForgotRoute from './routes/ForgotRoute'
import ResetPasswordResultRoute from './routes/ResetPasswordResultRoute'
import BackoffRoute from './routes/BackoffRoute'
import NewPasswordRoute from './routes/NewPasswordRoute'
import Verification from './routes/VerificationRoute'
import { ErrorPage } from '../views/ErrorPage'
import NotFoundRoute from './routes/NotFoundRoute'
import LoginExpiredRoute from './routes/LoginExpiredRoute'
import { createTheme, MuiThemeProvider } from '@material-ui/core'
import Router from 'react-router/lib/Router'
import { browserHistory, Route } from 'react-router'

const muiTheme = createTheme(
   {
      palette: {
         primary: {
            main: '#009DF0',
         },
         secondary: {
            main: '#C5ECF8'
         }
      }
   }
)

interface MainContainerState {
   error: null,
   errorInfo: null
}

class MainContainer extends Component<{}, MainContainerState> {
   constructor(props) {
      super(props)
      this.state = { error: null, errorInfo: null }
   }

   componentDidCatch(error, errorInfo) {
      this.setState({ error: error, errorInfo: errorInfo })
   }

   render() {
      if (this.state.error != null) {
      }

      return (
         <MuiThemeProvider theme={muiTheme}>
            <div>
               {
                  this.state.error != null ?
                     <ErrorPage/> :
                     <Router history={browserHistory}>
                        <Route path={(window as any).baseUrl} component={LoginRoute}/>
                        <Route path={window.SSOVerificationUrl} component={SSOVerificationRoute}/>
                        <Route path={'/servlet' + window.baseUrl} component={LoginRoute}/>
                        <Route path={window.forgotUrl} component={ForgotRoute}/>
                        <Route path={window.resetResultUrl} component={ResetPasswordResultRoute}/>
                        <Route path={window.backoffUrl} component={BackoffRoute}/>
                        <Route path={window.newPasswordUrl} component={NewPasswordRoute}/>
                        <Route path={window.verificationUrl} component={Verification}/>
                        <Route path={window.userLoginExpiredUrl} component={LoginExpiredRoute}/>
                        <Route path={window.baseUrl + '/*'} component={NotFoundRoute}/>
                     </Router>
               }
            </div>
         </MuiThemeProvider>
      )
   }
}

export default MainContainer