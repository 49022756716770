import ActionNames from '../api/ActionNames'
import { LoginStatus } from '../api/LoginStatus'

const initialState = {
   error: { isError: false, err: '' },
   responseAlert: '',
   password: null,
   redirectUrl: null,
   isSending: false,
   email: '',
   companyName: '',
   loginStatus: null,
   loginAttemptSuccessful: null,
}

export interface SSOVerificationState {
   error: { isError: boolean, err: string },
   responseAlert: string,
   password: string,
   redirectUrl: (string | Location) & Location,
   isSending: boolean,
   email: string,
   companyName: string,
   loginStatus: string,
   loginAttemptSuccessful: boolean,
   isLoggingIn: boolean,
}

const ssoVerification = (state = initialState, action) => {
   switch (action.type) {
      case ActionNames.CHANGE_PASSWORD:
         return Object.assign({}, state, {
            password: action.password,
            loginStatus: state.loginStatus === LoginStatus.ENTER_PASSWORD ? LoginStatus.ENTER_PASSWORD : null
         })
      case ActionNames.IS_SENDING:
         return Object.assign({}, state, { isSending: action.payload.isSending })
      case ActionNames.GOT_USER:
         return Object.assign({}, state, {
            email: action.user.email,
            companyName: action.user.companyName
         })
      case ActionNames.LOGGED_IN:
         return Object.assign({}, state, {
            redirectUrl: action.result.redirectUrl,
            loginStatus: action.result.loginStatus,
            loginAttemptSuccessful: action.result.successful
         })
      case ActionNames.ERROR:
         return Object.assign({}, state, {
            error: { isError: true, err: action.err }
         })
      case ActionNames.REMOVE_ALERT:
         return Object.assign({}, state, {
            responseAlert: initialState.responseAlert
         })
      default:
         return state
   }
}

export default ssoVerification