import React from 'react'
import { SupportLinks } from './SupportLinks'
import { localize } from '../api/Localize'
import { SurveyXactLogo } from './SurveyXactLogo'

type SupportBoxProps = {
   title?: string
}

export const SupportBox: React.FC<SupportBoxProps> = (props) => {

   const classname = 'support-box'

   return (
      <div className={classname}>
         <div className='support-inner-box'>

            <SurveyXactLogo/>

            {/*Title*/}
            <h1>{props.title}</h1>

            <p className="support-text">{localize.translate('supportInfoText')}</p>
            {/* Render the content in the box */}
            {props.children}

            {/*Support links*/}
            <SupportLinks/>
         </div>
      </div>
   )
}